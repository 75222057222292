<template xmlns="http://www.w3.org/1999/html">
    <div class="modal fade" id="authModal" tabindex="-1" aria-hidden="true">
        <Login @login_succeeded="onLoginSucceeded" :where="'user'" />
    </div>
</template>

<script setup>
    import Login from "../components/Login.vue";
</script>

<script>
export default {
    data() {
        return {
            user: null,
            email: null,
            password: null,
            showPassword: false,
            loginFail: false,
            authModal: null,
            is_login: "false",
            csrf_token: "",
            random_character_string: "",
            route_devices_store: "",
            route_sale: "",
            route_get_data_offline: "",
        };
    },
    async mounted() {
        this.csrf_token = document.getElementById('app').getAttribute('csrf_token');
        this.random_character_string = document.getElementById('app').getAttribute('random_character_string');
        this.route_devices_store = document.getElementById('app').getAttribute('route_devices_store');
        this.route_sale = document.getElementById('app').getAttribute('route_sale');
        this.route_get_data_offline = document.getElementById('app').getAttribute('route_get_data_offline');
        this.is_login = document.getElementById('app').getAttribute('is_login');

        if (this.is_login != "true") {
            this.authModal = new bootstrap.Modal('#authModal', {
                keyboard: false,
                backdrop: "static",
            })
            this.authModal.show();
        } else {
            this.init();
        }
    },
    methods: {
        init() {
            const indexedDB =
                window.indexedDB ||
                window.mozIndexedDB ||
                window.webkitIndexedDB ||
                window.msIndexedDB ||
                window.shimIndexedDB;

            if (!indexedDB) {
                console.log("IndexedDB could not be found in this browser.");
                return false;
            }

            let deviceCode = localStorage.getItem('DeviceId');
            const redirectToSale = () => {
                const route = this.route_sale;
                setTimeout(function() {
                    window.location.href=route
                }, 3000);
            }


            if (!deviceCode) {
                $.ajax({
                    headers: {
                        'X-CSRF-TOKEN': this.csrf_token
                    },
                    url: this.route_devices_store,
                    type: "POST",
                    data: {
                        device_code: this.random_character_string
                    },
                }).done(function (response) {
                    if (response.message == 'success') {
                        deviceCode = response.data;
                        localStorage.setItem('DeviceId', deviceCode);
                        redirectToSale();
                    }
                }).fail(function (request) {
                });
            } else {
                redirectToSale();
            }
        },
        onLoginSucceeded() {
            this.authModal.hide();
            this.init();
        }
    },
}
</script>
