<template xmlns="http://www.w3.org/1999/html">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content" style="min-height: 320px">
            <div class="modal-header">
                <button v-if="where == 'admin'" class="btn-close-modal" type="button" data-bs-dismiss="modal" aria-label="Close" style="background: transparent">
                    <svg xmlns="http://www.w3.org/2000/svg" width="27.48" height="27.48" viewBox="0 0 27.48 27.48">
                        <path id="合体_3-2" data-name="合体 3-2" d="M373.363-381.451l-11.241,11.243-2.5-2.5,11.242-11.242L359.624-395.19l2.5-2.5,11.241,11.242,11.242-11.242,2.5,2.5-11.243,11.241L387.1-372.707l-2.5,2.5Z" transform="translate(-359.624 397.688)" fill="#fff"/>
                    </svg>
                </button>
            </div>
            <div class="modal-body p-0 text-center">
                <div id="login-form" style="padding-top: 58px; padding-bottom: 61px;">
                    <h4 class=" mb-5 fs-4">
                      {{ where == "admin" ? "管理者アカウントでログインしてください。" : "認証アカウントでログインしてください。" }}
                    </h4>
                    <div class="m-auto" style="width: 340px;">
                        <input name="email" type="email" v-model="email" class="form-control mb-1 rounded-0" placeholder="メールアドレス" autocomplete="false">
                        <div class="input-group">
                            <input name="password" :type="showPassword ? 'text' : 'password'" v-model="password" class="form-control rounded-0" style="border-right: none" placeholder="スタッフコード">
                            <div class="input-group-addon" @click="showPassword = !showPassword"
                                  style="font-size: 22px; border: 1px solid #dee2e6; padding: 0 10px; border-left: 0;">
                                <i v-if="!showPassword" class="fa fa-eye-slash" aria-hidden="true"></i>
                                <i v-else class="fa fa-eye" aria-hidden="true"></i>
                            </div>
                        </div>
                        <p v-if="loginFail" class="text-danger mt-3">ログイン情報が正しくありません。</p>
                    </div>
                    <div class="w-100 mb-3">
                        <button type="button" class="c-white w-65 fs-24 text-center btn-app-default btn-login"
                            :class="!!email && email.length > 0 && !!password && password.length > 0 ? 'bg-primary-app' : 'bg-primary-light'" @click="login()">ログイン</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Login",
  props: [
    "where" // admin / user
  ],
  data() {
      return {
          user: null,
          email: null,
          password: null,
          showPassword: false,
          loginFail: false,
          authModal: null,
      };
  },
  async mounted() {
  },
  methods: {
      async login () {
          await axios.get('/sanctum/csrf-cookie')
          await axios.post('/login',{
              email: this.email,
              password: this.password,
          })
          .then((response) => {
              if (response.data.status) {
                  this.$emit("login_succeeded")
              } else {
                  this.loginFail = true
              }
          }).catch((errorResponse) => {
                  this.loginFail = true
          })
      },
  },
}
</script>
