<template xmlns="http://www.w3.org/1999/html">
    <div class="modal fade" id="authModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content" style="min-height: 320px">
                <div class="modal-header">
                </div>
                <div class="modal-body p-0 text-center">
                    <div id="login-form">
                        <h4 class=" fw-bold mb-5 fs-4" style="margin-top: 58px">認証アカウントでログインしてください</h4>
                        <div class="m-auto" style="width: 340px; margin-bottom: 87px!important;">
                            <input name="email" type="email" v-model="email" class="form-control mb-1 rounded-0" placeholder="メールアドレス" autocomplete="false">
                            <div class="input-group">
                                <input name="password" :type="showPassword ? 'text' : 'password'" v-model="password" class="form-control rounded-0" style="border-right: none" placeholder="スタッフコード">
                                <div class="input-group-addon" @click="showPassword = !showPassword"
                                     style="font-size: 22px; border: 1px solid #dee2e6; padding: 0 10px; border-left: 0;">
                                    <i v-if="!showPassword" class="fa fa-eye-slash" aria-hidden="true"></i>
                                    <i v-else class="fa fa-eye" aria-hidden="true"></i>
                                </div>
                            </div>
                            <p v-if="loginFail" class="text-danger mt-3">ログイン情報が正しくありません。</p>
                            <div class="px-5">
                                <button type="button" class="bg-blue-app text-white fs-6 fw-bold text-center btn-app-default border-2 border-blue-app rounded-5 btn-login" @click="login()">ログイン</button>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            user: null,
            email: null,
            password: null,
            showPassword: false,
            loginFail: false,
            authModal: null,
            is_login: "false",
            csrf_token: "",
            random_character_string: "",
            route_devices_store: "",
            route_sale: "",
            route_get_data_offline: "",
        };
    },
    async mounted() {
        this.csrf_token = document.getElementById('app').getAttribute('csrf_token');
        this.random_character_string = document.getElementById('app').getAttribute('random_character_string');
        this.route_devices_store = document.getElementById('app').getAttribute('route_devices_store');
        this.route_sale = document.getElementById('app').getAttribute('route_sale');
        this.route_get_data_offline = document.getElementById('app').getAttribute('route_get_data_offline');
        this.is_login = document.getElementById('app').getAttribute('is_login');

        if (this.is_login != "true") {
            this.authModal = new bootstrap.Modal('#authModal', {
                keyboard: false,
                backdrop: "static",
            })
            this.authModal.show();
        } else {
            this.init();
        }
    },
    methods: {
        async login () {
            await axios.get('/sanctum/csrf-cookie')
            await axios.post('/login',{
                email: this.email,
                password: this.password,
            })
            .then((response) => {
                if (response.data.status) {
                    this.authModal.hide();
                    this.init();
                } else {
                    this.loginFail = true
                }
            }).catch((errorResponse) => {
                    this.loginFail = true
            })
        },
        init() {
            const indexedDB =
                window.indexedDB ||
                window.mozIndexedDB ||
                window.webkitIndexedDB ||
                window.msIndexedDB ||
                window.shimIndexedDB;

            if (!indexedDB) {
                console.log("IndexedDB could not be found in this browser.");
                return false;
            }

            let deviceCode = localStorage.getItem('DeviceId');
            const redirectToSale = () => {
                const route = this.route_sale;
                setTimeout(function() {
                    window.location.href=route
                }, 3000);
            }


            if (!deviceCode) {
                $.ajax({
                    headers: {
                        'X-CSRF-TOKEN': this.csrf_token
                    },
                    url: this.route_devices_store,
                    type: "POST",
                    data: {
                        device_code: this.random_character_string
                    },
                }).done(function (response) {
                    if (response.message == 'success') {
                        deviceCode = response.data;
                        localStorage.setItem('DeviceId', deviceCode);
                        redirectToSale();
                    }
                }).fail(function (request) {
                });
            } else {
                redirectToSale();
            }
        },
    },
}
</script>
